import React, { Component } from 'react';
import { Modal } from 'glints-aries';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import { LocalStorageKeys } from 'src/common/constants';
import { removeNilProperty } from 'src/common/removeNilProperty';
import { pushGTMEvent } from 'src/common/utils/google-tag-manager';
import { hasLocalStorage } from 'src/common/utils/hasLocalStorage';
import { OpportunitiesShowLoginPopupVariant } from 'src/modules/Opportunities/constants';

import { isXssPayload } from '../../common/utils/security';
import { loginSignupModalText } from './Constants';
import { LoginSignUpModalContainer } from './LoginModal.sc';

const LoginForm = dynamic(() => import('./LoginForm'), { ssr: false });

class LoginSignupModal extends Component {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired,

    isLoadingLogin: PropTypes.bool.isRequired,
    isLoadingSignup: PropTypes.bool.isRequired,
    isFetchingMe: PropTypes.bool.isRequired,
    isVisible: PropTypes.bool.isRequired,
    source: PropTypes.string,
    loginError: PropTypes.object,
    me: PropTypes.object.isRequired,
    variant: PropTypes.string.isRequired,
    signUpError: PropTypes.object,
    userMeta: PropTypes.object.isRequired,
    router: PropTypes.object,

    clearLoginError: PropTypes.func.isRequired,
    clearSignUpError: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    updateMeta: PropTypes.func.isRequired,
    afterClose: PropTypes.func,
    formState: PropTypes.string,
    // afterSubmitAction is triggered after authentication and before modal closes
    afterSubmitAction: PropTypes.func,
    // eventsLoginSignUpModalAfterLoginCallback is set by
    // the action creator openLoginSignUpModal,
    // when used together with afterSubmitAction,
    // don't do duplicated things.
    eventsLoginSignUpModalAfterLoginCallback: PropTypes.func,
    /** eventsLoginSignUpModalAfterLoginCallback is set by
     * the action creator openLoginSignUpModal used to take
     * action after signup redirection
     */
    eventsLoginSignUpModalAfterSignUpClicked: PropTypes.func,
    disableCloseButtonAndClickOutside: PropTypes.bool,
    isShowLoginPopupEnabled: PropTypes.bool,
  };

  componentDidUpdate(prevProps) {
    const { source, isShowLoginPopupEnabled } = this.props;
    const showPopupOnboardingVariant = isShowLoginPopupEnabled
      ? OpportunitiesShowLoginPopupVariant.VISIBLE
      : OpportunitiesShowLoginPopupVariant.HIDDEN;

    if (source) {
      pushGTMEvent('glints/GoogleTagManager/LOGIN_POPUP_VIEWED', {
        payload: {
          source,
          ...(source === 'popup' && {
            showPopupOnboardingVariant: showPopupOnboardingVariant,
          }),
        },
      });
    }

    if (!prevProps.me.role && this.props.me.role) {
      this.afterAuthenticated();
    }
  }

  async componentWillUnmount() {
    document.removeEventListener('keydown', this.enterKeyPressedToLogin);
  }

  get modalConfig() {
    const { variant } = this.props;
    return loginSignupModalText[variant];
  }

  afterAuthenticated = async () => {
    const { userMeta, router, eventsLoginSignUpModalAfterLoginCallback } =
      this.props;

    if (Object.keys(userMeta).length) {
      await this.props.updateMeta(userMeta);
    }

    if (router.query && router.query.next && !isXssPayload(router.query.next)) {
      router.push({
        pathname: router.query.next,
        query: removeNilProperty({
          ...router.query,
          next: undefined,
          nextAs: undefined,
        }),
      });
    }

    if (typeof eventsLoginSignUpModalAfterLoginCallback === 'function') {
      eventsLoginSignUpModalAfterLoginCallback();
    }

    await this.onCloseModal();
  };

  onCloseModal = async () => {
    const { afterClose, source } = this.props;
    if (source === 'popup' && hasLocalStorage()) {
      localStorage.setItem(
        LocalStorageKeys.isLoginPopUpShown,
        // @ts-ignore
        true
      );
    }

    if (afterClose) {
      await afterClose();
    }
    this.props.onClose();
  };

  render() {
    const {
      isVisible,
      disableCloseButtonAndClickOutside,
      eventsLoginSignUpModalAfterSignUpClicked,
      source,
    } = this.props;
    const modalConfig = this.modalConfig;
    const { contentHeader, modalTitle } = modalConfig.login;
    return (
      <LoginSignUpModalContainer>
        <Modal
          id={isVisible && source ? 'login-signup-popup' : 'login-signup-modal'}
          title={<p className="modal-title large-text">{modalTitle}</p>}
          isVisible={isVisible}
          hideHeader={disableCloseButtonAndClickOutside}
          onClose={!disableCloseButtonAndClickOutside && this.onCloseModal}
        >
          <div className="large-text">{contentHeader}</div>
          <LoginForm
            isModal={true}
            afterSignUpClicked={eventsLoginSignUpModalAfterSignUpClicked}
          />
        </Modal>
      </LoginSignUpModalContainer>
    );
  }
}

export default LoginSignupModal;
