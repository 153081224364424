import { ThumborImageOptions } from 'src/common/getThumborImageURL';
import { URLUtils } from 'src/common/urlUtils/urlUtils';

export interface Size {
  width?: number;
  height?: number;
}

export enum AssetType {
  images = 'images',
  aboutPageAssets = 'about-page-assets',
  companyPhotos = 'company-photos',
  companyBannerPic = 'company-banner-pic',
  companyLogo = 'company-logo',
  content = 'content',
  defaultBannerPicLarge = 'default-banner-pic-large',
  defaultBannerPicSmall = 'default-banner-pic-small',
  docs = 'docs',
  eventBannerPic = 'event-banner-pic',
  promotionalBannerPic = 'promotional-banner-pic',
  expertClassBanner = 'expert-class-banner',
  jobCategoryBannerPic = 'job-category-banner-pic',
  jobBannerPic = 'job-banner-pic',
  jobDefaultBannerPic = 'job-default-banner-pic',
  jobBenefitsLogos = 'job-benefits-logos',
  perkPic = 'perk-pic',
  professionBannerPic = 'profession-banner-pic',
  profilePicture = 'profile-picture',
  profilePictureDefault = 'profile-picture-default',
  qna = 'qna',
  expertClassCertificates = 'expert-class-certificates',
  resume = 'resume',
  portfolioImage = 'portfolio-image',
  odysseyBadge = 'odyssey-badge',
  expertClassApplicationCollateralPhoto = 'expert-class/c2c-platform/class-application/collateral-photo',
}

export interface ImageAssetURLOptions {
  imageSrc: string;
  assetType?: AssetType;
  size?: Size;
}

export interface GlintsPictureProps
  extends Omit<ThumborImageProps, 'src' | 'onError' | 'serverURL'> {
  className?: string;
  name?: string;
  assetType?: AssetType | string;
  fallback?: string;
  fallbackAssetType?: AssetType | string;
  sizes?: string;
  urlUtils?: URLUtils;
  thumborBaseUrl?: string;
  isCircleImage?: boolean;
}

export interface ThumborImageProps extends ThumborImageOptions, ImgAttributes {
  src: string;
  lazy?: boolean;
  aspectRatio?: number;
  breakpoints?: number | number[];
  onError: () => void;
}

export const ThumborImageOptionKeys: (keyof ThumborImageOptions)[] = [
  'serverURL',
  'src',
  'size',
  'hmac',
  'trim',
  'trimSource',
  'crop',
  'fitIn',
  'horizontalAlign',
  'verticalAlign',
  'smartCrop',
  'filters',
];

export type ImgAttributes = React.ImgHTMLAttributes<HTMLImageElement>;

export const ImgAttributeNames: (keyof ImgAttributes)[] = [
  'className',
  'alt',
  'crossOrigin',
  'decoding',
  'height',
  'loading',
  'referrerPolicy',
  'sizes',
  'src',
  'srcSet',
  'useMap',
  'width',
];
