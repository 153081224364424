import React from 'react';
import camelCase from 'lodash/camelCase';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { getYearsOfExperienceMessage } from 'src/common/utils/experienceHelpers';
import {
  LokaliseOnsiteMessage,
  OpportunityHelper,
} from 'src/modules/Opportunity/OpportunityHelper';

import { useFeatureFlagEnabledByCountryCode } from '../../common/hooks/useFeatureFlagEnabledByCountryCode';
import { JobTypeMessages } from '../../common/messages/jobs';
import { getCountryWithSGFallback } from '../../modules/ClientConfig/Selectors';
import {
  EducationLevel,
  JobInterface,
} from '../../modules/Opportunity/interface';
import { getViewEducationLevelEnabled } from '../../modules/Unleash/Selectors';
import * as S from './CompactOpportunityCardTags.sc';

interface Props {
  opportunity: JobInterface;
  maxCharacters: number;
  maxTags: number;
}

export function CompactOpportunityCardTags({
  opportunity,
  maxCharacters = 60,
  maxTags = 5,
}: Props) {
  const countryCode = useSelector(getCountryWithSGFallback);
  const isEducationLevelInfoEnabled = useFeatureFlagEnabledByCountryCode(
    getViewEducationLevelEnabled
  )(countryCode);

  const {
    workArrangementOption,
    type: opportunityType,
    minYearsOfExperience: min,
    maxYearsOfExperience: max,
    educationLevel: educationLevelFromOpportunity,
    skills,
  } = opportunity || {};

  const { lokaliseId, lokaliseDefaultMessage } =
    OpportunityHelper.getWorkArrangementOptionLokalise(workArrangementOption);

  const jobLocationMessage =
    lokaliseId !== LokaliseOnsiteMessage.lokaliseId ? (
      <FormattedMessage
        id={lokaliseId}
        defaultMessage={lokaliseDefaultMessage}
      />
    ) : null;

  const jobTypeMessage = JobTypeMessages[opportunityType] ? (
    <FormattedMessage {...JobTypeMessages[opportunityType]} />
  ) : null;

  const yearsOfExperienceMessage = getYearsOfExperienceMessage(min, max);

  const getEducationLevel = (level?: string) => {
    if (!level) return null;
    for (const enumValue in EducationLevel) {
      if (enumValue === level) return camelCase(level);
    }
    return null;
  };

  const educationLevel = getEducationLevel(educationLevelFromOpportunity);
  const educationLevelMessage =
    isEducationLevelInfoEnabled && educationLevel ? (
      <FormattedMessage
        id="minimum-education-level"
        defaultMessage="Minimal {level, select,
        primarySchool {Primary School}
        secondarySchool {Secondary School}
        highSchool {High School / Vocational Diploma}
        diploma {Associate Degree}
        collegeDegree {College Degree}
        bachelorDegree {Bachelor’s Degree}
        masterDegree {Master’s Degree}
        doctorate {Doctorate Degree} other {}}"
        values={{ level: educationLevel }}
      />
    ) : null;

  const tags = [
    jobLocationMessage,
    jobTypeMessage,
    yearsOfExperienceMessage,
    educationLevelMessage,
    ...(skills?.map(({ skill }) => skill.name) || []),
  ];

  const totalCharacters = tags.join('').length;

  // If the total character length of all tags is greater than the max characters
  // allowed, then concat the tags and show a plus sign, indicating more tags,
  // and how many there are, that are not shown.
  if (totalCharacters > maxCharacters || tags.length > maxTags) {
    const concattedTags: (string | React.JSX.Element)[] = [];
    tags.forEach((tag) => {
      if (
        tag &&
        concattedTags.join('').length < maxCharacters &&
        concattedTags.length < maxTags
      ) {
        const selectedTag =
          typeof tag === 'string' ? (
            <FormattedMessage id={tag} defaultMessage={tag} />
          ) : (
            tag
          );
        concattedTags.push(selectedTag);
      }
    });

    return (
      <>
        {opportunity.company?.isVIP && (
          <S.VIPTag>
            <FormattedMessage id="vip" defaultMessage="Premium Employer" />
          </S.VIPTag>
        )}
        {concattedTags.map((tag, index) => {
          return tag ? <S.Tag key={index}>{tag}</S.Tag> : null;
        })}
        {tags.length - concattedTags.length > 0 && (
          <S.Tag>+{tags.length - concattedTags.length}</S.Tag>
        )}
      </>
    );
  }

  return (
    <>
      {opportunity.company?.isVIP && (
        <S.VIPTag>
          <FormattedMessage id="vip" defaultMessage="Premium Employer" />
        </S.VIPTag>
      )}
      {tags.map((tag, index) =>
        tag ? <S.Tag key={index}>{tag}</S.Tag> : null
      )}
    </>
  );
}
