import React from 'react';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

import { getClassName } from 'src/common/ComponentHelpers';
import useConfig from 'src/common/hooks/useConfig';
import { NewFeatureBadge } from 'src/components/NewFeatureBadge';

import * as S from './NavigationComponents.sc';
import { MenuBaseItemType } from './types';

interface NavigationItemsProps {
  handleCloseDrawer: () => void;
  menuItems?: MenuBaseItemType[];
}

export const NavigationItems: React.FC<NavigationItemsProps> = ({
  handleCloseDrawer,
  menuItems,
}) => {
  const config = useConfig();
  const country = config?.COUNTRY;

  return (
    <>
      {menuItems?.flat().map((item, i) => (
        <Choose key={item}>
          <When condition={item?.external}>
            {!country && (i === 1 || i === 3) && <S.Divider />}
            {!country && i === 1 && (
              <S.SectionTitleWrapper>Hiring solutions</S.SectionTitleWrapper>
            )}
            <a
              href={item?.to}
              target="_blank"
              key={item?.label}
              className={getClassName(item?.label)}
              rel="noreferrer"
            >
              <S.TextWrapper>
                <Choose>
                  <When condition={!country}>
                    <S.LinkWrapper>
                      <FormattedMessage {...item?.message} />
                      <S.ArrowRight />
                    </S.LinkWrapper>
                  </When>
                  <Otherwise>
                    <S.UpperCaseWrapper
                      role="button"
                      aria-label={`${item?.label} page`}
                    >
                      <FormattedMessage {...item?.message} />
                    </S.UpperCaseWrapper>
                  </Otherwise>
                </Choose>
                {item?.new && (
                  <S.NewFeatureBadgeContainer>
                    <NewFeatureBadge />
                  </S.NewFeatureBadgeContainer>
                )}
              </S.TextWrapper>
            </a>
          </When>
          <Otherwise>
            <Link
              key={item?.to}
              href={item?.to ?? ''}
              as={item?.as || item?.to}
              prefetch={false}
            >
              <a
                target={item?.target}
                onClick={handleCloseDrawer}
                className={getClassName(item?.label)}
              >
                <S.TextWrapper role="button" aria-label={`${item?.label} page`}>
                  <S.UpperCaseWrapper>
                    <FormattedMessage {...item?.message} />
                  </S.UpperCaseWrapper>
                  {item?.new && (
                    <S.NewFeatureBadgeContainer>
                      <NewFeatureBadge />
                    </S.NewFeatureBadgeContainer>
                  )}
                </S.TextWrapper>
              </a>
            </Link>
          </Otherwise>
        </Choose>
      ))}
    </>
  );
};
