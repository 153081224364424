import { isEmpty } from 'lodash';
import { encode, ParsedUrlQuery } from 'querystring';

import { generateSlug } from 'src/common/generateSlug';
import {
  ID_JOB_EXPLORE_URL,
  JOB_EXPLORE_URL,
  ROUTES,
  VN_JOB_EXPLORE_URL,
} from 'src/common/routes';
import { JobLinkProps } from 'src/common/types/jobLinkProps';
import { Company } from 'src/global/models/Company';
import { Event } from 'src/global/models/Event';
import { Job } from 'src/global/models/Job';
import { UTM_REFERRER } from 'src/modules/Opportunities/constants';
import { createUrlSlug } from 'src/modules/Opportunities/utils/createUrlSlug';

import {
  JOB_TITLE,
  SHARE_ID,
  TRACE_INFO,
  UTM_CAMPAIGN,
  UTM_MEDIUM,
  UTM_REFERRER_PARAM,
} from '../constants';
import { CountryCodes } from '../enums';

export type JobDetailsPageQueryParams = {
  utmMedium?: string | null;
  utmCampaign?: string | null;
  shareId?: string | null;
  utmReferrer?: string | null;
  traceInfo?: string | null;
};

export const generateJobDetailsPageQueryString = (
  params: JobDetailsPageQueryParams
) => {
  const queryParams: string[] = [];

  const addQueryParam = (key: string, value?: string | null) => {
    if (value) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }
  };

  addQueryParam(UTM_MEDIUM, params.utmMedium);
  addQueryParam(UTM_CAMPAIGN, params.utmCampaign);
  addQueryParam(SHARE_ID, params.shareId);
  addQueryParam(UTM_REFERRER_PARAM, params.utmReferrer);
  addQueryParam(TRACE_INFO, params.traceInfo);

  return queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
};

export const getJobWithCountryFilterURL = (
  country: CountryCodes,
  canApplyCountriesQuery: boolean
) => {
  const isID = country === CountryCodes.ID;
  const isVN = country === CountryCodes.VN;

  return isID
    ? ID_JOB_EXPLORE_URL
    : isVN
    ? VN_JOB_EXPLORE_URL
    : country && canApplyCountriesQuery
    ? JOB_EXPLORE_URL
    : `/${ROUTES.opportunitiesJobsExplore}`;
};

export const getJobWithCompanyFilterURL = (
  companyId: string,
  companyName: string,
  country?: CountryCodes,
  language?: string,
  query?: string
) => {
  return companyId
    ? `/company/${createUrlSlug({
        slug: generateSlug(companyName),
        countryCode: country,
        language,
      })}/${companyId}?${query}`
    : `/${ROUTES.opportunitiesJobsExplore}?${query}`;
};

/**
 * Given a company, generate and return the props for Link.
 *
 * @param company Company object
 */
export const getCompanyLinkProps = (company: Company) => {
  if (isEmpty(company)) {
    return null;
  }

  const id = company.id;
  const companyName = generateSlug(company.name);
  const as = companyName
    ? `/${ROUTES.companies}/${companyName}/${id}`
    : `/${ROUTES.companies}/${id}`;

  return {
    href: {
      pathname: `/${ROUTES.company}`,
      query: {
        id,
        companyName,
      },
    },
    as,
  };
};

export const getJobLinkProps = (
  opportunity: Partial<Job>,
  referrer?: UTM_REFERRER,
  currentURLSearchParams?: URLSearchParams
): JobLinkProps => {
  const id = opportunity.id;
  const title = generateSlug(opportunity.title);
  const traceInfo = opportunity.traceInfo;

  const utmReferrer =
    currentURLSearchParams?.get(UTM_REFERRER_PARAM) ?? referrer;
  const utmMedium = currentURLSearchParams?.get(UTM_MEDIUM);
  const utmCampaign = currentURLSearchParams?.get(UTM_CAMPAIGN);
  const shareId = currentURLSearchParams?.get(SHARE_ID);
  const selectedTraceInfo =
    currentURLSearchParams?.get(TRACE_INFO) ?? traceInfo;

  const query = generateJobDetailsPageQueryString({
    utmMedium,
    utmCampaign,
    shareId,
    utmReferrer,
    traceInfo: selectedTraceInfo,
  });

  return {
    href: {
      pathname: `/${ROUTES.opportunitiesJob}`,
      query: {
        id,
        title,
        utm_referrer: referrer ?? utmReferrer,
        utm_campaign: utmCampaign,
        utm_medium: utmMedium,
        traceInfo: selectedTraceInfo,
        shareId,
      },
    },
    as: `/${ROUTES.opportunitiesJobs}/${title}/${id}${query}`,
  };
};

export const getJobLinkPropsFromAsPath = (asPath: string) => {
  const urlParams = new URLSearchParams(asPath.split('?')[1]);
  const utmReferrer = urlParams.get(UTM_REFERRER_PARAM);
  const traceInfo = urlParams.get(TRACE_INFO);
  const utmMedium = urlParams.get(UTM_MEDIUM);
  const utmCampaign = urlParams.get(UTM_CAMPAIGN);
  const shareId = urlParams.get(SHARE_ID);

  const jobTitle = urlParams.get(JOB_TITLE);
  const jobId = urlParams.get('id');

  const query = generateJobDetailsPageQueryString({
    utmMedium,
    utmCampaign,
    shareId,
    utmReferrer,
    traceInfo,
  });

  return {
    href: {
      pathname: `/${ROUTES.opportunitiesJob}`,
      query: {
        id: jobId,
        title: jobTitle,
        utm_referrer: utmReferrer,
        utm_campaign: utmCampaign,
        utm_medium: utmMedium,
        traceInfo,
        shareId,
      },
    },
    as: `/${ROUTES.opportunitiesJobs}/${jobTitle}/${jobId}${query}`,
  };
};

export const getApplyJobLinkProps = (
  opportunity: Partial<Job>,
  queryParam: ParsedUrlQuery
) => {
  const urlQueryString = new URLSearchParams(encode(queryParam));
  const props = getJobLinkProps(opportunity, undefined, urlQueryString);
  const as = props.as.replace('?', '/apply?');

  return {
    as: as,
    href: props.href,
    query: queryParam,
  };
};

export const getExpertClassLinkProps = (expertClass: Partial<Event>) => {
  const titleSlug = generateSlug(expertClass.title);
  return {
    href: `/${ROUTES.expertClassDetail}?title=${titleSlug}&shortId=${expertClass.shortId}`,
    as: `/${ROUTES.expertClass}/${titleSlug}/${expertClass.shortId}`,
  };
};

export const getExpertClassOrderLinkProps = (order: { id: string }) => {
  const { id } = order;

  return {
    href: {
      pathname: `/${ROUTES.expertClassOrders}/id`,
      query: {
        id,
      },
    },
    as: `/${ROUTES.expertClassOrders}/${id}`,
  };
};

export const getExpertClassConfirmOrderPageUrl = (expertClassShortId: string) =>
  `/${ROUTES.confirmOrder}?expertClassId=${expertClassShortId}`;
