import React from 'react';

import { getRandomFallbackProfilePicture } from '../../common/AssetManager';
import GlintsPicture from './GlintsPicture';

interface ProfilePictureProps {
  userID?: string;
  profilePic?: string;
  sizes?: string;
  breakpoints?: number;
  alt?: string;
  [key: string]: any;
}

const ProfilePicture = React.memo<ProfilePictureProps>(
  ({ userID, profilePic, ...otherProps }) => {
    if (!userID) {
      return null;
    }

    const fallback = getRandomFallbackProfilePicture(userID);
    const fallbackAssetType = 'profile-picture-default';

    return (
      <GlintsPicture
        name={profilePic}
        assetType="profile-picture"
        fallback={fallback}
        fallbackAssetType={fallbackAssetType}
        aspectRatio={1}
        isCircleImage={true}
        {...otherProps}
      />
    );
  }
);

ProfilePicture.displayName = 'ProfilePicture';

export default ProfilePicture;
