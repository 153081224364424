import React, { useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { Typography } from 'glints-aries/lib/@next';
import { get } from 'lodash';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import useConfig from 'src/common/hooks/useConfig';
import { useGetRegularScreenSize } from 'src/common/hooks/useScreenChecking';
import { AssetType } from 'src/components/GlintsPicture/interfaces';
import { InView } from 'src/components/InView';
import { Company, companySizeMap } from 'src/global/models/Company';
import { Job, JobStatus } from 'src/global/models/Job';

import { CompactOpportunityCard } from '../../components/Cards/CompactOpportunityCard';
import { UTM_REFERRER } from '../Opportunities/constants';
import { getCompanyById } from '../Opportunity/graphql';
import {
  CardContainer,
  CardContent,
  CardTitle,
  Container,
  GalleryContainer,
  JobList,
  Main,
  NewCard,
  NewCardHeaderLink,
  SubGalleryContainer,
  TextBetweenLine,
  TitleSection,
} from './CompanyPage.sc';
import {
  CompanyTabTypes,
  TabListItems,
} from './components/TopFoldCompanySection/const';
import { trackViewCompaniesPages } from './GTMActions';
import { getDescriptionRawAndImages } from './helper';

const Footer = dynamic(() => import('src/components/Footer/FooterPsychFlat'), {
  ssr: false,
});
const DraftjsReader = dynamic(
  () => import('src/components/Editor/DraftjsReader'),
  {
    ssr: false,
  }
);
const Gallery = dynamic(() => import('src/components/Gallery'), {
  ssr: false,
});

const ContactSection = dynamic(
  import('src/modules/Company/components/ContactSection').then(
    (mod) => mod.ContactSection
  )
);
const JobsSection = dynamic(
  import('src/modules/Company/components/JobsSection').then(
    (mod) => mod.JobsSection
  )
);
const SimilarCompaniesSection = dynamic(
  import('src/modules/Company/components/SimilarCompaniesSection').then(
    (mod) => mod.SimilarCompaniesSection
  )
);
const TopFoldCompanySection = dynamic(
  import('src/modules/Company/components/TopFoldCompanySection').then(
    (mod) => mod.TopFoldCompanySection
  )
);

interface Props {
  company: Company;
}

const CompanyPage = ({ company }: Props) => {
  const router = useRouter();
  const dispatch = useDispatch();

  /**
   * Dirty fix workaround: To handle cases where the `company` prop is missing certain fields during the last data fetch.
   * Sometimes, the latest company data returned is shorter or missing some fields compared to the previous data.
   * This might happen due to issues in the data fetching process during SSR and CSR.
   * This is a temporary measure to avoid issues with incomplete data being passed.
   * TODO (Tai, Farhan): Implement full SSR support for this page.
   */
  const initialCompanyRef = useRef<Company | null>(null);
  if (initialCompanyRef.current === null) {
    initialCompanyRef.current = company;
  }
  const safeCompany = {
    ...initialCompanyRef.current,
  };

  const { loading, error, data } = useQuery<{
    getCompanyById: { jobs: Job[] };
  }>(getCompanyById, {
    variables: {
      // Use the safeCompany object to avoid issues with missing data
      id: safeCompany.id,
      data: {
        limit: 20,
        status: JobStatus.OPEN,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const relevantJobs = data?.getCompanyById?.jobs;

  const currentScreen = useGetRegularScreenSize();
  const isMobileScreen = currentScreen === 'mobile';

  const topJobs = relevantJobs?.slice(0, 3);

  const { candidateBase } = useConfig({
    country: 'COUNTRY',
    language: 'LANG',
    candidateBase: 'CANDIDATE_BASE',
  });

  const industryName = get(company, 'links.industry.name');
  const countryName = get(company, 'links.country.name');

  useEffect(() => {
    if (!company || loading || error) {
      return;
    }

    const { CountryCode, size, status, descriptionRaw } = company || {};
    const absoluteUrl = candidateBase + router.asPath;
    const hasDescription = Boolean(descriptionRaw);
    const hasOpenJobs = Boolean(relevantJobs && relevantJobs.length);
    const companySize = companySizeMap[size]?.defaultMessage;

    const eventProperties = {
      landingPageURL: absoluteUrl,
      companyCountryCode: CountryCode,
      companySize,
      companyStatus: status,
      companyIndustry: industryName,
      hasDescription,
      hasOpenJobs,
    };

    dispatch(trackViewCompaniesPages(eventProperties));
  }, [
    dispatch,
    company,
    candidateBase,
    router.asPath,
    industryName,
    loading,
    error,
    relevantJobs,
  ]);

  const {
    selectedDescriptionRaw: selectedCompanyDescriptionRaw,
    imagesInRaw: imagesInCompanyDescriptionRaw,
  } = getDescriptionRawAndImages(company?.descriptionRaw);

  const {
    selectedDescriptionRaw: selectedCompanyCultureRaw,
    imagesInRaw: imagesInCompanyCulture,
  } = getDescriptionRawAndImages(company?.cultureRaw);

  const topJobsSectionRef = useRef<HTMLElement | null>(null);
  const descriptionSectionRef = useRef<HTMLElement | null>(null);
  const cultureSectionRef = useRef<HTMLElement | null>(null);
  const contactSectionRef = useRef<HTMLElement | null>(null);
  const gallerySectionRef = useRef<HTMLElement | null>(null);
  const jobsSectionRef = useRef<HTMLElement | null>(null);
  const similarCompaniesSectionRef = useRef<HTMLElement | null>(null);

  const tabToSectionRefMap: Record<
    CompanyTabTypes,
    React.RefObject<HTMLElement>
  > = {
    topJobsTab: topJobsSectionRef,
    descriptionTab: descriptionSectionRef,
    cultureTab: cultureSectionRef,
    contactTab: contactSectionRef,
    galleryTab: gallerySectionRef,
    jobsTab: jobsSectionRef,
    similarCompaniesTab: similarCompaniesSectionRef,
  };

  const availableSectionRefs = TabListItems.map(
    (tab) => tabToSectionRefMap[tab]
  ).filter(Boolean);

  const scrollToJobsSection = () => {
    jobsSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToSimilarCompaniesSection = () => {
    similarCompaniesSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Main>
      <TopFoldCompanySection
        company={safeCompany}
        relevantJobsLength={relevantJobs?.length}
        sectionRefs={availableSectionRefs}
      />

      <Container>
        <If condition={topJobs?.length > 0}>
          <NewCard id="top-jobs-section" ref={topJobsSectionRef}>
            <CardTitle>
              <FormattedMessage id="text-top-jobs" defaultMessage="Top Jobs" />
              <NewCardHeaderLink onClick={scrollToJobsSection}>
                <FormattedMessage
                  id="interactive-view-all-jobs"
                  defaultMessage="View All Jobs"
                />
              </NewCardHeaderLink>
            </CardTitle>

            <CardContent>
              <JobList>
                <CardContainer>
                  {topJobs?.map((opportunity, index) => (
                    <CompactOpportunityCard
                      key={opportunity.id}
                      index={`${index}`}
                      company={safeCompany}
                      opportunity={opportunity}
                      // TODO: remove this when CompactOpportunityCard is migrated to TS
                      // @ts-ignore
                      referrer={UTM_REFERRER.COMPANY_PROFILE}
                    />
                  ))}
                </CardContainer>
              </JobList>
            </CardContent>
          </NewCard>
        </If>

        <NewCard id="description-section" ref={descriptionSectionRef}>
          <CardTitle>
            <FormattedMessage
              id="company-page.overview-section.title2"
              defaultMessage="Company Details"
              values={{ companyName: company?.name }}
            />
          </CardTitle>
          <CardContent>
            <TitleSection>
              <FormattedMessage
                id="company-page.overview-section.description.title"
                defaultMessage="Description"
              />
            </TitleSection>
            <Typography variant="subtitle2">
              <Choose>
                <When condition={company && selectedCompanyDescriptionRaw}>
                  <DraftjsReader
                    draftjsObject={selectedCompanyDescriptionRaw}
                  />
                </When>
                <Otherwise>
                  <FormattedMessage
                    id="company-page.overview-section.empty"
                    defaultMessage="The company has not completed this section."
                  />
                </Otherwise>
              </Choose>
            </Typography>
          </CardContent>
          <If condition={imagesInCompanyDescriptionRaw}>
            <SubGalleryContainer>
              <Gallery
                photos={imagesInCompanyDescriptionRaw}
                size="large"
                showHeader={isMobileScreen}
                header={{
                  altText: 'Company Gallery',
                  assetType: AssetType.companyPhotos,
                }}
                currentScreen={currentScreen}
                renderEmptyGallery={() => <></>}
              />
            </SubGalleryContainer>
          </If>
        </NewCard>

        <NewCard id="culture-section" ref={cultureSectionRef}>
          <CardTitle>
            <FormattedMessage
              id="company-page.overview-section.culture.title"
              defaultMessage="Culture"
            />
          </CardTitle>
          <CardContent>
            <Choose>
              <When condition={company && selectedCompanyCultureRaw}>
                <Typography variant="subtitle2">
                  <DraftjsReader draftjsObject={selectedCompanyCultureRaw} />
                </Typography>
              </When>
              <Otherwise>
                <FormattedMessage
                  id="company-page.overview-section.empty"
                  defaultMessage="The company has not completed this section."
                  tagName="p"
                />
              </Otherwise>
            </Choose>
          </CardContent>
          <If condition={imagesInCompanyCulture}>
            <SubGalleryContainer>
              <Gallery
                photos={imagesInCompanyCulture}
                size="large"
                showHeader={isMobileScreen}
                currentScreen={currentScreen}
                header={{
                  altText: 'Company Gallery',
                  assetType: AssetType.companyPhotos,
                }}
                renderEmptyGallery={() => <></>}
              />
            </SubGalleryContainer>
          </If>
        </NewCard>

        <If
          condition={
            company &&
            (company.address || company.socialMediaSites || company.website)
          }
        >
          <NewCard id="contact-section" ref={contactSectionRef}>
            <CardTitle>
              <FormattedMessage
                id="company-page.overview-section.contact.title"
                defaultMessage="Contact"
              />
            </CardTitle>
            <ContactSection company={company} />
          </NewCard>
        </If>

        <NewCard id="gallery-section" ref={gallerySectionRef}>
          <CardTitle>
            <FormattedMessage
              id="company-page.gallery-section.title"
              defaultMessage="Gallery"
            />
          </CardTitle>
          <GalleryContainer>
            <Gallery
              photos={company?.photos ?? []}
              size="large"
              showHeader={isMobileScreen}
              currentScreen={currentScreen}
              header={{
                altText: 'Company Gallery',
                assetType: AssetType.companyPhotos,
              }}
              renderEmptyGallery={() => (
                <FormattedMessage
                  id="company-page.gallery-section.empty"
                  defaultMessage="The company hasn't added any photos yet."
                  tagName="p"
                />
              )}
            />
          </GalleryContainer>
        </NewCard>

        <NewCard id="jobs-section" ref={jobsSectionRef}>
          <CardTitle>
            <FormattedMessage
              id="company-page.jobs-section.title-v2"
              defaultMessage="Jobs"
              values={{ numberOfJobs: relevantJobs?.length }}
            />
          </CardTitle>
          <CardContent>
            <JobsSection
              relevantJobs={relevantJobs}
              company={safeCompany}
              scrollToSimilarCompaniesSection={scrollToSimilarCompaniesSection}
            />
          </CardContent>
        </NewCard>

        <TextBetweenLine>
          <Typography variant="body2">
            <FormattedMessage
              id="text-similar-companies-for-you"
              defaultMessage="Similar companies for you"
            />
          </Typography>
        </TextBetweenLine>

        <NewCard
          id="similar-companies-section"
          ref={similarCompaniesSectionRef}
        >
          <CardTitle>
            <FormattedMessage
              id="text-similar-industry-companies"
              defaultMessage="Similar {Industry} Companies in {Country}"
              values={{
                Industry: industryName,
                Country: countryName,
              }}
            />
          </CardTitle>
          <CardContent>
            <SimilarCompaniesSection
              companyId={company?.id}
              countryCode={company?.CountryCode}
            />
          </CardContent>
        </NewCard>
      </Container>

      <InView>
        <Footer />
      </InView>
    </Main>
  );
};

CompanyPage.propTypes = {
  company: PropTypes.object,
  jobs: PropTypes.arrayOf(PropTypes.object),
};

export default CompanyPage;
