import { ApolloError, gql } from '@apollo/client';

import { Unleash } from 'src/global/models';

export interface GetEnabledFeatureFlagsResult {
  getEnabledFeatureFlags: Unleash[] | undefined;
  loading: boolean;
  errors?: ApolloError;
}

export const queryGetEnabledFeatureFlags = gql`
  query getEnabledFeatureFlags(
    $deviceId: String!
    $platform: String
    $featureFlagNames: [String!]
  ) {
    getEnabledFeatureFlags(
      data: {
        deviceId: $deviceId
        platform: $platform
        featureFlagNames: $featureFlagNames
      }
    ) {
      name
      variant {
        type
        value
      }
    }
  }
`;
