import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import NoSSR from '@mpth/react-no-ssr';
import classNames from 'classnames';
import {
  ClipboardCheckOutlineIcon,
  Greyscale,
  PrimaryColor,
  WarningCircleSolidIcon,
} from 'glints-aries';
import { capitalize, get, snakeCase } from 'lodash';
import moment from 'moment';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { JobCardContext } from 'src/common/contexts/JobCardContext';
import { doesSalaryExist } from 'src/common/doesSalaryExist';
import { CompanyStatus } from 'src/common/enums/companies';
import { JobsSortByKey } from 'src/common/enums/jobs';
import { getJobCardInfo } from 'src/common/getJobCardInfo';
import { useResponsiveOpenTargetAttribute } from 'src/common/hooks/useResponsiveOpenTargetAttribute';
import { useIsMobile } from 'src/common/hooks/useScreenChecking';
import { useHostNameAndBaseName } from 'src/common/hooks/useUrl';
import { isConfidentialCompany } from 'src/common/isConfidentialCompany';
import isServer from 'src/common/isServer';
import { getCompanyLinkProps, getJobLinkProps } from 'src/common/links';
import { removeSalaryProperty } from 'src/common/removeSalaryProperty';
import { JobLinkProps } from 'src/common/types/jobLinkProps';
import { getHierarchicalJobCategory } from 'src/common/utils/hierarchicalJobCategoryHelpers';
import CheckMarkHotJobBadge from 'src/components/Badges/CheckMarkHotJobBadge';
import CardJobLocation from 'src/components/Cards/CardJobLocation';
import {
  TimeAgo,
  wasPostedOrUpdatedRecently,
} from 'src/components/FormattedDateTime/TimeAgo';
import { GoogleTagManager } from 'src/components/GoogleTagManager';
import { FraudReportFlag, JobStatus } from 'src/global/models/Job';
import { BOOKMARK_TYPES, BookmarkButton } from 'src/modules/Bookmark';
import { TrackImpressions } from 'src/modules/Opportunities/components/TrackImpressions/TrackImpressions';
import { UTM_REFERRER } from 'src/modules/Opportunities/constants';
import { JobTypeMessageMap } from 'src/modules/Opportunities/pages/Explore/Components/FilterSection/messages';
import { SearchRouterQueryKey } from 'src/modules/Opportunities/pages/Explore/types';
import { getMeQuery } from 'src/modules/Profile/graphql/me';
import { getIsAuthenticated } from 'src/modules/Session/Selectors';
import {
  getRecommendationsExperimentVariant,
  getSearchJobsExperimentVariant,
} from 'src/modules/Unleash/Selectors';

import * as Styles from './CompactOpportunityCard.sc';
import { JobCardTitleNoStyleAnchor } from './CompactOpportunityCard.sc';
import { CompactJobOpportunityCardSalary } from './CompactOpportunityCardSalary';
import { CompactOpportunityCardTags } from './CompactOpportunityCardTags';

export const CompactJobCard = ({
  opportunity,
  index,
  company,
  companyLinkProps,
  jobLinkProps,
  className = '',
  isNotBookmarkedClassName,
  hasClosed = false,
  border = 'round',
  active = false,
  activatable = false,
  onClick,
  shouldHighlighHotJobs,
  shouldTrackImpressions = false,
  referrer,
}) => {
  const dstExperimentRecommendationsExperiment = useSelector(
    getRecommendationsExperimentVariant
  );
  const dstSearchJobsExperiment = useSelector(getSearchJobsExperimentVariant);
  const updatedAt = moment(opportunity.updatedAt);
  const isRecent = wasPostedOrUpdatedRecently(opportunity.updatedAt);
  opportunity = doesSalaryExist(opportunity.salaries)
    ? removeSalaryProperty(opportunity)
    : opportunity;

  const isConfidential = isConfidentialCompany(company?.name);
  const target = useResponsiveOpenTargetAttribute();

  const showActivelyHiring = opportunity.isActivelyHiring;

  const jobId = get(opportunity, 'id');
  const isHotJob = shouldHighlighHotJobs && opportunity.isHot;
  const jobCardInfo = getJobCardInfo(opportunity);
  const companyId = get(company, 'id');

  const router = useRouter();
  const isLoggedIn = useSelector(getIsAuthenticated);
  const { data } = useQuery(getMeQuery, {
    skip: !isLoggedIn,
  });

  const skills = data?.getMe?.skills || [];
  const hasSkills = skills.some(skill => skill.isNormalized);

  const searchedKeywords = router.query[SearchRouterQueryKey.Keyword];
  const searchedLocation = router.query[SearchRouterQueryKey.Location];
  const sortBy =
    router.query[SearchRouterQueryKey.SortBy] || JobsSortByKey.RELEVANCY;

  const { jobCategoryName, jobSubCategoryName, jobRoleName } =
    getHierarchicalJobCategory(opportunity);

  const source = router.pathname;
  const isFypPage = source === '/opportunities/jobs/recommended';
  const fypClassNames = isFypPage
    ? classNames(className, 'listRecommendedJobs')
    : className;

  const isApplied = opportunity.isApplied;

  // Have different maximum character length for similar
  // jobs section and FYP page due to more narrow job card
  function getMaximumTagCharacters() {
    if (referrer === UTM_REFERRER.SIMILAR) {
      return 40;
    } else if (isFypPage) {
      return 50;
    } else {
      return 60;
    }
  }

  const renderCompactJobCard = () => (
    <Styles.CompactJobCard
      data-gtm-job-id={jobId}
      data-gtm-job-category={jobCategoryName}
      data-gtm-job-sub-category={jobSubCategoryName}
      data-gtm-job-role={jobRoleName}
      data-gtm-job-type={snakeCase(
        capitalize(
          JobTypeMessageMap[opportunity.type]?.props?.defaultMessage ?? ''
        )
      )}
      data-gtm-job-company-id={opportunity?.company?.id}
      data-gtm-job-idx={index}
      data-gtm-is-confidential-company={isConfidential}
      data-gtm-is-hot-job={opportunity.isHot}
      data-gtm-searched-keywords={searchedKeywords}
      data-gtm-searched-location={searchedLocation}
      data-gtm-job-card-info={jobCardInfo}
      data-gtm-has-skills={hasSkills}
      data-gtm-source={source}
      data-gtm-exp-search-jobs={dstSearchJobsExperiment}
      data-gtm-exp-recommendations={dstExperimentRecommendationsExperiment}
      aria-label={`Job card ${index}`}
      border={border}
      active={active}
      activatable={activatable}
      onClick={onClick}
      isHotJob={isHotJob}
      isApplied={isApplied}
      isFypPage={isFypPage}
      className={fypClassNames}
    >
      {isApplied && (
        <Styles.AppliedTagContainer>
          <Styles.AppliedIcon />
          <FormattedMessage id="text-check-applied" defaultMessage="Applied" />
        </Styles.AppliedTagContainer>
      )}

      <Styles.OpportunityUpper>
        {!isApplied &&
          opportunity?.fraudReportFlag ===
            FraudReportFlag.VERIFIED_SCAM_FRAUD && (
            <Styles.VerifiedScamFraudJobTagContainer>
              <Styles.VerifiedScamFraudJobTag>
                <FormattedMessage
                  id="text-scam-fraud-verified"
                  defaultMessage="Job Removed"
                />
              </Styles.VerifiedScamFraudJobTag>
            </Styles.VerifiedScamFraudJobTagContainer>
          )}

        <Styles.JobTitleSalaryWrapper>
          <Styles.JobTitle aria-label={`Job card title ${index}`}>
            <Choose>
              <When condition={jobLinkProps}>
                <Link
                  href={jobLinkProps.href}
                  as={jobLinkProps.as}
                  passHref={true}
                >
                  <JobCardTitleNoStyleAnchor>
                    {get(opportunity, 'title')}
                  </JobCardTitleNoStyleAnchor>
                </Link>
              </When>
              <Otherwise>{get(opportunity, 'title')}</Otherwise>
            </Choose>
          </Styles.JobTitle>

          <CompactJobOpportunityCardSalary opportunity={opportunity} />
        </Styles.JobTitleSalaryWrapper>
        <Styles.TagsWrapper>
          <CompactOpportunityCardTags
            maxCharacters={getMaximumTagCharacters()}
            maxTags={5}
            opportunity={opportunity}
          />
        </Styles.TagsWrapper>
        <Styles.CompanyLinkContainer data-cy="company_name_job_card">
          <Styles.CompanyAvatarWrapper>
            <Styles.CompanyAvatar
              logo={
                isConfidential
                  ? 'powered-by-glints-logo.png'
                  : get(company, 'logo')
              }
              alt={get(company, 'name')}
              sizes="32px"
              breakpoints={60}
            />
          </Styles.CompanyAvatarWrapper>
          <Styles.CompanyDetailContainer data-cy="company_name_job_card">
            <Styles.CompanyInformation>
              <If condition={company?.status === CompanyStatus.VERIFIED}>
                <Choose>
                  <When condition={company?.isVIP}>
                    <img
                      src="images/icons/vip-badge.svg"
                      alt="vip shield badge"
                      loading="lazy"
                    />
                  </When>
                  <Otherwise>
                    <img
                      src="images/icons/verified-badge.svg"
                      alt="verified shield badge"
                      loading="lazy"
                    />
                  </Otherwise>
                </Choose>
              </If>
              <Link {...companyLinkProps} passHref={true}>
                <Styles.CompanyLink
                  aria-label={`Job card company ${index}`}
                  target={target}
                  onClick={e => e.stopPropagation()}
                >
                  {get(company, 'name') || (
                    <FormattedMessage
                      id="job_details_page.company.name"
                      defaultMessage="Confidential Company"
                    />
                  )}
                </Styles.CompanyLink>
              </Link>
            </Styles.CompanyInformation>

            <Styles.OpportunityInfo>
              <CardJobLocation job={opportunity} />
            </Styles.OpportunityInfo>
          </Styles.CompanyDetailContainer>
        </Styles.CompanyLinkContainer>
      </Styles.OpportunityUpper>
      <Styles.OpportunityLower>
        {!isFypPage && <Styles.Divider />}
        <Styles.OpportunityFooter>
          <Styles.OpportunityMeta>
            <If condition={shouldHighlighHotJobs && opportunity.isHot}>
              <div>
                {shouldHighlighHotJobs && opportunity.isHot && (
                  <CheckMarkHotJobBadge />
                )}
              </div>
            </If>
            <If condition={hasClosed}>
              <WarningCircleSolidIcon color={PrimaryColor.glintsred} />{' '}
              <FormattedMessage
                id="job.card.job.closed.message"
                defaultMessage="This job has been closed"
              >
                {message => (
                  <Styles.ClosedJobMessage>{message}</Styles.ClosedJobMessage>
                )}
              </FormattedMessage>
            </If>
            <NoSSR>
              <If condition={!hasClosed && !isServer}>
                <Styles.CardBottomFlexContainer>
                  {showActivelyHiring && (
                    <Styles.IsActivelyHiringContainer>
                      <ClipboardCheckOutlineIcon height="16px" width="16px" />
                      <FormattedMessage
                        id="actively.hiring"
                        defaultMessage="Actively Hiring"
                      />
                    </Styles.IsActivelyHiringContainer>
                  )}
                  <Styles.UpdatedTimeContainer>
                    {showActivelyHiring && (
                      <Styles.DotIcon
                        color={Greyscale.lightgrey}
                        width="4px"
                        height="4px"
                      />
                    )}
                    <Styles.UpdatedAtMessage data-recent={isRecent}>
                      <TimeAgo time={updatedAt} />
                    </Styles.UpdatedAtMessage>
                  </Styles.UpdatedTimeContainer>
                </Styles.CardBottomFlexContainer>
              </If>
            </NoSSR>
          </Styles.OpportunityMeta>
          <div>
            <Styles.BookmarkIconContainer>
              <GoogleTagManager tag="job_card-bookmark_job">
                <BookmarkButton
                  bookmarkEntity={opportunity}
                  data-cy="bookmarked_icon"
                  isNotBookmarkedClassName={isNotBookmarkedClassName}
                  type={BOOKMARK_TYPES.opportunity}
                  id={opportunity.id}
                  aria-label={`Job card bookmark ${index}`}
                  showIcon={true}
                />
              </GoogleTagManager>
            </Styles.BookmarkIconContainer>
          </div>
        </Styles.OpportunityFooter>
      </Styles.OpportunityLower>
    </Styles.CompactJobCard>
  );

  return (
    <Choose>
      <When condition={shouldTrackImpressions}>
        <TrackImpressions
          jobId={jobId}
          isHot={Boolean(isHotJob)}
          jobIndexPosition={index}
          searchedKeywords={searchedKeywords}
          searchedLocation={searchedLocation}
          jobCardInfo={jobCardInfo}
          companyId={companyId}
          jobCategory={jobCategoryName}
          jobSubCategory={jobSubCategoryName}
          jobRole={jobRoleName}
          sortBy={sortBy}
          hasSkills={hasSkills}
          source={source}
        >
          {renderCompactJobCard()}
        </TrackImpressions>
      </When>
      <Otherwise>{renderCompactJobCard()}</Otherwise>
    </Choose>
  );
};

CompactJobCard.propTypes = {
  company: PropTypes.object.isRequired,
  index: PropTypes.number,
  opportunity: PropTypes.object.isRequired,
  companyLinkProps: PropTypes.object.isRequired,
  jobLinkProps: PropTypes.objectOf(JobLinkProps),
  isNotBookmarkedClassName: PropTypes.string.isRequired,
  hasClosed: PropTypes.bool,
  className: PropTypes.string,
  border: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  activatable: PropTypes.bool,
  shouldHighlighHotJobs: PropTypes.bool,
  setToolTipModalOpen: PropTypes.bool,
  shouldTrackImpressions: PropTypes.bool,
  referrer: PropTypes.oneOf(UTM_REFERRER),
};

const _CompactOpportunityCard = ({
  company,
  index = '',
  opportunity,
  shouldHighlightClosedJobs = false,
  shouldHighlighHotJobs = false,
  shouldTrackImpressions = false,
  referrer,
}) => {
  /** A workaround for a bug where the company is undefined cause it's not fetched yet */
  const companyLinkProps = company
    ? getCompanyLinkProps(company)
    : { href: '/' };
  const jobLinkProps = getJobLinkProps(opportunity, referrer);

  const isStatusClosed = opportunity.status === JobStatus.CLOSED;
  const hasClosed = shouldHighlightClosedJobs ? isStatusClosed : false;

  const { jobLinkClassName, isNotBookmarkedClassName } =
    useContext(JobCardContext);

  const isMobile = useIsMobile();
  const host = useHostNameAndBaseName();

  const isLoggedIn = useSelector(getIsAuthenticated);
  const { data } = useQuery(getMeQuery, {
    skip: !isLoggedIn,
  });
  const skills = data?.getMe?.skills || [];
  const hasSkills = skills.some(skill => skill.isNormalized);
  const router = useRouter();

  const handleCardClick = async () => {
    const url = new URL(`${host}${jobLinkProps.as}`);

    if (isMobile) {
      await router.push(jobLinkProps.href, jobLinkProps.as);
    } else {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Styles.CompactJobCardWrapper
      showHoverAnimation={false}
      data-position={index}
      data-has-skills={hasSkills}
      hasClosed={hasClosed}
      isApplied={opportunity.isApplied}
    >
      <CompactJobCard
        onClick={handleCardClick}
        opportunity={opportunity}
        className={jobLinkClassName}
        index={index}
        company={company}
        companyLinkProps={companyLinkProps}
        jobLinkProps={jobLinkProps}
        isNotBookmarkedClassName={isNotBookmarkedClassName}
        shouldHighlighHotJobs={shouldHighlighHotJobs}
        shouldTrackImpressions={shouldTrackImpressions}
        referrer={referrer}
      />
    </Styles.CompactJobCardWrapper>
  );
};

_CompactOpportunityCard.propTypes = {
  country: PropTypes.string,
  company: PropTypes.object.isRequired,
  index: PropTypes.number,
  opportunity: PropTypes.object.isRequired,
  shouldHighlightClosedJobs: PropTypes.bool,
  shouldHighlighHotJobs: PropTypes.bool,
  shouldTrackImpressions: PropTypes.bool,
  referrer: PropTypes.oneOf(UTM_REFERRER),
};

export const CompactOpportunityCard = React.memo(_CompactOpportunityCard);
