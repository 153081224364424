import React from 'react';
import { useApolloClient } from '@apollo/client';
import {
  FileSolidIcon,
  Greyscale,
  PowerIcon,
  SettingSolidIcon,
  TicketSolidIcon,
  UserIcon,
} from 'glints-aries';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { closeSidebar } from 'src/actions/app';
import { ROUTES } from 'src/common/routes';
import { ProfilePicture as GlintsProfilePicture } from 'src/components/GlintsPicture';
import { getIsExpertClassOrdersEnabled } from 'src/modules/ExpertClassOrder/Selectors';
import { logout } from 'src/modules/Session/Actions';
import { getIsAuthenticated } from 'src/modules/Session/Selectors';
import { getUser } from 'src/selectors/user';

import { settingPageLinkMessage } from '../../messages';
import { MobileLoginSignupButton } from './MobileLoginSignupButton';
import * as S from './NavigationComponents.sc';

export const AuthenticationMenuItemsSection = React.memo(() => {
  const dispatch = useDispatch();
  const router = useRouter();
  const me = useSelector(getUser);
  const authenticated = useSelector(getIsAuthenticated);
  const isExpertClassOrdersEnabled = useSelector(getIsExpertClassOrdersEnabled);
  const apolloClient = useApolloClient();

  const handleCloseSidebar = () => {
    dispatch(closeSidebar());
  };

  const handleLogout = () => {
    dispatch(logout(apolloClient));
    dispatch(closeSidebar());
  };

  const redirectURL = (args: string) => {
    router.push(args);
    handleCloseSidebar();
  };

  const renderLoginAndSignUpSection = () => (
    <>
      <MobileLoginSignupButton
        href="/login"
        onClick={handleCloseSidebar}
        type="login"
      >
        <S.TextWrapper
          textTransform="uppercase"
          role="button"
          aria-label="Login"
        >
          <FormattedMessage id="login" defaultMessage="Login" tagName="span" />
        </S.TextWrapper>
      </MobileLoginSignupButton>
      <MobileLoginSignupButton
        href="/signup"
        onClick={handleCloseSidebar}
        type="signup"
      >
        <S.TextWrapper
          textTransform="uppercase"
          role="button"
          aria-label="Sign Up"
        >
          <FormattedMessage
            id="menu.button.signup"
            defaultMessage="Sign Up"
            tagName="span"
          />
        </S.TextWrapper>
      </MobileLoginSignupButton>
    </>
  );

  const renderLoggedInFooterSection = () => (
    <>
      <S.TextWrapper role="button" aria-label="My Profile">
        <S.FlexCenter onClick={() => redirectURL('/profile')}>
          <UserIcon color={Greyscale.white} />
          <FormattedMessage
            id="my.profile"
            defaultMessage="My Profile"
            tagName="span"
          />
        </S.FlexCenter>
      </S.TextWrapper>

      <S.TextWrapper role="button" aria-label="My Applications">
        <S.FlexCenter onClick={() => redirectURL(`/${ROUTES.applications}`)}>
          <FileSolidIcon color={Greyscale.white} />
          <FormattedMessage
            id="my.applications"
            defaultMessage="My Applications"
            tagName="span"
          />
        </S.FlexCenter>
      </S.TextWrapper>

      {isExpertClassOrdersEnabled && (
        <S.TextWrapper role="button" aria-label="My Tickets">
          <S.FlexCenter
            onClick={() => redirectURL(`/${ROUTES.expertClassOrders}`)}
          >
            <TicketSolidIcon color={Greyscale.white} />
            <FormattedMessage
              id="user-menu.expert-class-ticket"
              defaultMessage="My Tickets"
              tagName="span"
            />
          </S.FlexCenter>
        </S.TextWrapper>
      )}

      <S.TextWrapper role="button" aria-label="Account Settings">
        <S.FlexCenter onClick={() => redirectURL('/settings')}>
          <SettingSolidIcon color={Greyscale.white} />
          <FormattedMessage {...settingPageLinkMessage} tagName="span" />
        </S.FlexCenter>
      </S.TextWrapper>

      <S.TextWrapper onClick={handleLogout} role="button" aria-label="Logout">
        <S.FlexCenter>
          <PowerIcon color={Greyscale.white} />
          <FormattedMessage
            id="logout"
            defaultMessage="Logout"
            tagName="span"
          />
        </S.FlexCenter>
      </S.TextWrapper>
    </>
  );

  const renderLoggedInSection = () => (
    <div>
      <Link href="/profile" as="/profile">
        <a onClick={handleCloseSidebar}>
          <S.FlexCenter>
            <S.ProfilePictureWrapper>
              <GlintsProfilePicture
                profilePic={me.profilePic}
                userID={me.id}
                sizes="140px"
                breakpoints={150}
                alt="Profile Picture"
              />
            </S.ProfilePictureWrapper>
            <S.ProfileText>
              {me.firstName} {me.lastName}
            </S.ProfileText>
          </S.FlexCenter>
        </a>
      </Link>
      {renderLoggedInFooterSection()}
    </div>
  );

  return (
    <>
      {authenticated ? renderLoggedInSection() : renderLoginAndSignUpSection()}
      <S.Divider />
    </>
  );
});
AuthenticationMenuItemsSection.displayName = 'AuthenticationMenuItemsSection';
