import { CountryCodes } from 'src/common/enums';

import { ExperimentVariant } from './constants';

export const getCountryCodesFromVariantString = (featureValue: string) =>
  featureValue.split(',').map((s) => s.trim() as CountryCodes);

export const variantToTrackingCode: Record<ExperimentVariant, string> = {
  VARIANT_A: 'A',
  VARIANT_B: 'B',
};

export const getTrackingVariantCode = (variant: ExperimentVariant) =>
  variantToTrackingCode[variant];
