export const UnleashFeatureNames = {
  dstTWJobFilter: 'dstTWJobFilter',
  dstBuilderIntegration: 'dstBuilderIntegration',
  dstFollowUpRequest: 'dstFollowUpRequest',
  dstHideApplicationPageBanner: 'dstHideApplicationPageBanner',
  dstHideApplicationPageArchiveTitle: 'dstHideApplicationPageArchiveTitle',
  dstFollowerNotificationPreference: 'dstFollowerNotificationPreference',
  dstOdyssey: 'dstOdyssey',
  dstIsSupersonicBannerEnabled: 'dstIsSupersonicBannerEnabled',
  dstInterviewProcess: 'dstInterviewProcess',
  dstEnableMobileDownloadBanner: 'dstEnableMobileDownloadBanner',
  dstApplicationStatusTracking: 'dstApplicationStatusTracking',
  dstImproveJobSearchFilter: 'dstImproveJobSearchFilter',
  dstEmptyJobSearchRedirectToFYPCTA: 'dstEmptyJobSearchRedirectToFYPCTA',
  dstShowRecommendationCard: 'dstShowRecommendationCard',
  dstResumeParsingEnabled: 'dstResumeParsingEnabled',
  dstHideJobCategoryFilter: 'dstHideJobCategoryFilter',
  allExperimentsExploreAndForYou: 'allExperimentsExploreAndForYou',
  dstCreateStreamChannel: 'dstCreateStreamChannel',
  dstCompanyNormalisedLocationEnabled: 'dstCompanyNormalisedLocationEnabled',
  dstViewEducationLevelEnabled: 'dstViewEducationLevelEnabled',
  dstChatApplicationStatusEnabled: 'dstChatApplicationStatusEnabled',
  dstNudgeChatWithHiringManager: 'dstNudgeChatWithHiringManager',
  dstWhatsappSupport: 'dstWhatsappSupport',
  dstSearchJobFilterV2: 'dstSearchJobFilterV2',
  dstExperimentOpportunitySkillMatch: 'dstExperimentOpportunitySkillMatch',
  dstHideResumeSectionInApplicationPage:
    'dstHideResumeSectionInApplicationPage',
  dstHideSkillsV2: 'dstHideSkillsV2',
  dstGetJobTitleSuggestionV2: 'dstGetJobTitleSuggestionV2',
  dstExperimentApiProvider: 'dstExperimentApiProvider',
  dstApplyJobV2: 'dstApplyJobV2',
  dstExperimentJobDetailRevising: 'dstExperimentJobDetailRevising',
  dstShowMoreJobRecommendationFilters: 'dstShowMoreJobRecommendationFilters',
  dstExperimentApplicationWarning: 'dstExperimentApplicationWarning',
  dstShowApplyOnAppButton: 'dstShowApplyOnAppButton',
  dstForYouV3: 'dstForYouV3',
  dstMigrationToApiV2: 'dstMigrationToApiV2',
  dstShowApplicationForm: 'dstShowApplicationForm',
  dstShowLoginPopup: 'dstShowLoginPopup',
  dstExperimentSearchJobV3: 'dstExperimentSearchJobV3',
  dstExperimentExplorePageFilterOrder: 'dstExperimentExplorePageFilterOrder',
  dstEnableMobileDownloadModal: 'dstEnableMobileDownloadModal',
  dstAirBridgeLinksEnabled: 'dstAirBridgeLinksEnabled',
  // ! Important: Please read this before adding a new feature flag
  /**
   * How to add a new feature flags
   * - Add a new value in @constant UnleashFeatureNames
   *    - If this feature flag for authenticated user flow only, add it to @constant AuthenticatedFeatureFlags
   *    - Otherwise, if it's only for unauthenticated user flow, add it to @constant UnauthenticatedFeatureFlags
   *    - If it's enabled for both user state authenticated/unauthenticated, add it both to:
   *        - @constant AuthenticatedFeatureFlags
   *        - @constant UnauthenticatedFeatureFlags
   */
};

export const AuthenticatedFeatureFlags = [
  UnleashFeatureNames.dstTWJobFilter,
  UnleashFeatureNames.dstBuilderIntegration,
  UnleashFeatureNames.dstFollowUpRequest,
  UnleashFeatureNames.dstHideApplicationPageBanner,
  UnleashFeatureNames.dstHideApplicationPageArchiveTitle,
  UnleashFeatureNames.dstFollowerNotificationPreference,
  UnleashFeatureNames.dstOdyssey,
  UnleashFeatureNames.dstIsSupersonicBannerEnabled,
  UnleashFeatureNames.dstInterviewProcess,
  UnleashFeatureNames.dstEnableMobileDownloadBanner,
  UnleashFeatureNames.dstApplicationStatusTracking,
  UnleashFeatureNames.dstImproveJobSearchFilter,
  UnleashFeatureNames.dstEmptyJobSearchRedirectToFYPCTA,
  UnleashFeatureNames.dstShowRecommendationCard,
  UnleashFeatureNames.dstResumeParsingEnabled,
  UnleashFeatureNames.dstHideJobCategoryFilter,
  UnleashFeatureNames.allExperimentsExploreAndForYou,
  UnleashFeatureNames.dstCreateStreamChannel,
  UnleashFeatureNames.dstCompanyNormalisedLocationEnabled,
  UnleashFeatureNames.dstViewEducationLevelEnabled,
  UnleashFeatureNames.dstChatApplicationStatusEnabled,
  UnleashFeatureNames.dstNudgeChatWithHiringManager,
  UnleashFeatureNames.dstWhatsappSupport,
  UnleashFeatureNames.dstSearchJobFilterV2,
  UnleashFeatureNames.dstExperimentOpportunitySkillMatch,
  UnleashFeatureNames.dstHideResumeSectionInApplicationPage,
  UnleashFeatureNames.dstHideSkillsV2,
  UnleashFeatureNames.dstGetJobTitleSuggestionV2,
  UnleashFeatureNames.dstExperimentApiProvider,
  UnleashFeatureNames.dstApplyJobV2,
  UnleashFeatureNames.dstExperimentJobDetailRevising,
  UnleashFeatureNames.dstShowMoreJobRecommendationFilters,
  UnleashFeatureNames.dstExperimentApplicationWarning,
  UnleashFeatureNames.dstShowApplyOnAppButton,
  UnleashFeatureNames.dstForYouV3,
  UnleashFeatureNames.dstMigrationToApiV2,
  UnleashFeatureNames.dstShowApplicationForm,
  UnleashFeatureNames.dstShowLoginPopup,
  UnleashFeatureNames.dstExperimentSearchJobV3,
  UnleashFeatureNames.dstExperimentExplorePageFilterOrder,
  UnleashFeatureNames.dstEnableMobileDownloadModal,
  UnleashFeatureNames.dstAirBridgeLinksEnabled,
];

export const UnauthenticatedFeatureFlags = [
  UnleashFeatureNames.dstTWJobFilter,
  UnleashFeatureNames.dstBuilderIntegration,
  UnleashFeatureNames.dstFollowUpRequest,
  UnleashFeatureNames.dstHideApplicationPageBanner,
  UnleashFeatureNames.dstHideApplicationPageArchiveTitle,
  UnleashFeatureNames.dstFollowerNotificationPreference,
  UnleashFeatureNames.dstOdyssey,
  UnleashFeatureNames.dstIsSupersonicBannerEnabled,
  UnleashFeatureNames.dstInterviewProcess,
  UnleashFeatureNames.dstEnableMobileDownloadBanner,
  UnleashFeatureNames.dstApplicationStatusTracking,
  UnleashFeatureNames.dstImproveJobSearchFilter,
  UnleashFeatureNames.dstEmptyJobSearchRedirectToFYPCTA,
  UnleashFeatureNames.dstShowRecommendationCard,
  UnleashFeatureNames.dstResumeParsingEnabled,
  UnleashFeatureNames.dstHideJobCategoryFilter,
  UnleashFeatureNames.allExperimentsExploreAndForYou,
  UnleashFeatureNames.dstCreateStreamChannel,
  UnleashFeatureNames.dstCompanyNormalisedLocationEnabled,
  UnleashFeatureNames.dstViewEducationLevelEnabled,
  UnleashFeatureNames.dstChatApplicationStatusEnabled,
  UnleashFeatureNames.dstNudgeChatWithHiringManager,
  UnleashFeatureNames.dstWhatsappSupport,
  UnleashFeatureNames.dstSearchJobFilterV2,
  UnleashFeatureNames.dstExperimentOpportunitySkillMatch,
  UnleashFeatureNames.dstHideResumeSectionInApplicationPage,
  UnleashFeatureNames.dstHideSkillsV2,
  UnleashFeatureNames.dstGetJobTitleSuggestionV2,
  UnleashFeatureNames.dstExperimentApiProvider,
  UnleashFeatureNames.dstApplyJobV2,
  UnleashFeatureNames.dstExperimentJobDetailRevising,
  UnleashFeatureNames.dstShowMoreJobRecommendationFilters,
  UnleashFeatureNames.dstExperimentApplicationWarning,
  UnleashFeatureNames.dstShowApplyOnAppButton,
  UnleashFeatureNames.dstForYouV3,
  UnleashFeatureNames.dstMigrationToApiV2,
  UnleashFeatureNames.dstShowApplicationForm,
  UnleashFeatureNames.dstShowLoginPopup,
  UnleashFeatureNames.dstExperimentSearchJobV3,
  UnleashFeatureNames.dstExperimentExplorePageFilterOrder,
  UnleashFeatureNames.dstEnableMobileDownloadModal,
  UnleashFeatureNames.dstAirBridgeLinksEnabled,
];

export const ExperimentApiVariants = {
  V1: 'V1',
  V2: 'V2',
  V3: 'V3',
} as const;

export const ExperimentApiProviders = {
  AWS: 'AWS',
  ALICLOUD: 'ALICLOUD',
} as const;

export const BasedExperimentVariants = {
  VARIANT_A: 'VARIANT_A',
  VARIANT_B: 'VARIANT_B',
} as const;

export type ExperimentVariant =
  (typeof BasedExperimentVariants)[keyof typeof BasedExperimentVariants];
